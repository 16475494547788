import { useNavigate } from 'react-router-dom'
import { FC } from 'react'

import { useStartAdditionalGenerationMutation } from '../../api/appApi'
import { ADDITIONAL_PLAN_INFO } from '../../utils/constants'
import { useAppSelector } from '../../redux/hooks'
import useViewport from '../../hooks/useViewport'

import GenerateMoreImageBlack from '../../assets/generate-more-black.svg'
import GenerateMoreImage from '../../assets/generate-more.svg'

type GenerateMoreProps = {
    id: string
}

const GenerateMore: FC<GenerateMoreProps> = ({ id }) => {
    const subscription = useAppSelector((state) => state.app.subscription)
    const theme = useAppSelector((state) => state.app.theme)

    const { isMobile } = useViewport()

    const navigate = useNavigate()

    const [startAdditionalGeneration] = useStartAdditionalGenerationMutation()

    const handleGenerateMore = () => {
        if (subscription?.additional_payment) {
            startAdditionalGeneration(id)
        } else {
            navigate(`/checkout/${ADDITIONAL_PLAN_INFO.stripe_price}/${ADDITIONAL_PLAN_INFO.name}?generation=${id}`)
        }
    }

    return (
        <div className="w-[360px] min-w-[360px] pr-12 mobile:w-full mobile:p-0 mobile:h-[66px] mobile:fixed mobile:bottom-0 mobile:left-0">
            <div className="w-full bg-inputTransparentBackgroundNoOpacity rounded-[24px] p-6 pt-12 flex flex-col items-center gap-4 mobile:flex-row mobile:p-4 mobile:rounded-none">
                <div className="flex flex-col gap-3 items-center w-full">
                    <img
                        className='mobile:hidden'
                        width={140}
                        src={theme === 'light' ? GenerateMoreImage : GenerateMoreImageBlack}
                        alt=""
                    />
                    <p className="text-center text-text text-[19px] leading-[23px] tracking-[-0.02em] font-semibold mobile:text-start mobile:text-[14px] mobile:leading-[17px]">
                        For only $1 generate{" "}
                        <br className='mobile:hidden' />
                        40 more images!
                    </p>
                </div>
                <button onClick={handleGenerateMore} className="h-[44px] w-full rounded-lg px-4 bg-button text-white font-[Inter] text-[14px] leading-[]17px] font-semibold hover:bg-buttonHover transition-colors mobile:h-[32px] mobile:w-fit">
                    {isMobile ? 'Generate' : 'Generate More Images'}
                </button>
            </div>
        </div>
    )
}

export default GenerateMore