import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth"

import { auth } from "../firebase/config"

export const convertDateToLocaleString = (date: string) => {
    return new Date(date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
}

export const checkEstimatedTimeLeft = (date: string | null) => {
    if (!date) return "1 minute remaining"

    const finishDate = new Date(date)

    const now = new Date()
    const diff = finishDate.getTime() - now.getTime()

    if (diff > 0) {
        const hours = Math.floor(diff / 1000 / 60 / 60)
        const minutes = Math.floor(diff / 1000 / 60) - hours * 60

        if (hours === 0) return `${minutes > 0 ? minutes : 1} ${minutes > 1 ? 'minutes' : 'minute'} remaining`

        return `${hours}h ${minutes}m`
    } else {
        return "1 minute remaining"
    }
}

export const calculatePercentageProgress = (startDate: string | null, endDate: string | null) => {
    if (!startDate || !endDate) return 0

    const start = new Date(startDate)
    const end = new Date(endDate)
    const now = new Date()

    const total = end.getTime() - start.getTime()
    const current = now.getTime() - start.getTime()
    const progress = (current / total) * 100

    return progress <= 100 ? progress : 100
}

export const validateUserPassword = async (password: string) => {
    try {
        if (auth.currentUser === null) return false

        const credential = EmailAuthProvider.credential(
            auth.currentUser.email || "",
            password
        )

        const { user } = await reauthenticateWithCredential(
            auth.currentUser,
            credential
        )

        if (user) {
            return true
        }
    } catch (error) {
        return false
    }
}