import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react"

import { useDeleteImagesForGenerationMutation } from "../../api/appApi"
import { useCreationContext } from "../../context/CreationContext"
import { useAppSelector } from "../../redux/hooks"
import useViewport from "../../hooks/useViewport"

import RemoveUploadedImageModal from "../../modals/RemoveUploadedImageModal"
import UploadedImageItem from "./UploadedImageItem"

import CompleteValidation from "../../assets/creation/complete-validation.svg"
import Validating from "../../assets/creation/validating.svg"
import Trash from "../../assets/creation/trash.svg"
import Close from "../../assets/close.svg"
import Plus from "../../assets/plus.svg"

const UploadedImages = () => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showErrorBlock, setShowErrorBlock] = useState<boolean>(true)
    const theme = useAppSelector((state) => state.app.theme)

    const inputRef = useRef<HTMLInputElement>(null)
    const singleInputRef = useRef<HTMLInputElement>(null)

    const { isMobile } = useViewport()

    const ctx = useCreationContext()

    const [deleteImages] = useDeleteImagesForGenerationMutation()

    const imagesWithError = useMemo(() => {
        return ctx?.uploadedImages.filter((image) => 'is_valid' in image && !image.is_valid).length
    }, [ctx?.uploadedImages])

    const handelConfirmDelete = () => {
        setShowDeleteModal(true)
    }

    const handleSelectAll = () => {
        if (ctx?.selectedUploadedImages.length === ctx?.uploadedImages.length) {
            ctx?.setSelectedUploadedImages([])
        } else if (ctx?.uploadedImages) {
            ctx.setSelectedUploadedImages(ctx.uploadedImages.map((_, index) => index))
        }
    }

    const handleAddImage = () => {
        inputRef.current?.click()
    }

    const handleUploadFiles = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            ctx?.setUploadedImages([...ctx.uploadedImages, ...Array.from(e.target.files)])
        }
    }

    const handleImageUploaded = (e: ChangeEvent<HTMLInputElement>) => {
        const image = ctx?.uploadedImages.filter((image) => 'is_valid' in image && !image.is_valid)[0]
        const index = ctx?.uploadedImages.findIndex((image) => 'is_valid' in image && !image.is_valid)

        if (e.target.files && e.target.files.length > 0 && image) {
            if (image instanceof File || !ctx?.id) {
                return
            } else {
                deleteImages({ id: ctx?.id, data: { ids: [image.id], select_all: false } })

                ctx?.setUploadedImages(
                    ctx.uploadedImages.filter((_, i) => i !== index).concat(Array.from(e.target.files))
                )
            }
        }
    }

    useEffect(() => {
        if (ctx?.isValidating) {
            const interval = setInterval(() => {
                ctx?.setTrainingProgress((prev) => {
                    if (prev < 95) {
                        const images = ctx?.imagesValidating || 15
                        const percentPerImage = Math.floor(95 / images)

                        ctx?.setValidatedImageIndex((prev) => prev + 1)

                        if (prev + percentPerImage > 99) {
                            return 99
                        } else {
                            return prev + percentPerImage
                        }
                    } else {
                        clearInterval(interval)
                        return 99
                    }
                })
            }, 1200)

            return () => {
                clearInterval(interval)
            }
        }
    }, [ctx?.isValidating, ctx?.imagesValidating, ctx])

    return (
        <div className="flex flex-col p-12 gap-6 h-[calc(100vh-158px)] overflow-y-auto custom-scroll mobile:py-6 mobile:px-4 mobile:h-[calc(100dvh-258px)]">
            <div className="flex items-center justify-between gap-2">
                <h5 className="text-text font-[Sora] text-[32px] leading-[40px] tracking-[-0.02em] font-bold mobile:text-[19px] mobile:leading-[23px]">
                    {ctx?.selectedUploadedImages && ctx?.selectedUploadedImages.length > 0 ? `${ctx?.selectedUploadedImages.length} Photo Selected` : 'Uploaded Photos'}
                </h5>
                <div className="flex items-center gap-2">
                    <button
                        disabled={ctx?.isValidating || ctx?.isCompressingImages}
                        onClick={handleSelectAll}
                        className="text-text rounded-lg h-[44px] px-4 font-[Inter] font-semibold text-[14px] leading-[17px] bg-inputBackground hover:bg-inputBorder transition-colors mobile:h-[32px] mobile:px-3 disabled:hover:bg-inputBackground"
                    >
                        {ctx?.selectedUploadedImages.length === ctx?.uploadedImages.length ? 'Unselect All' : 'Select All'}
                    </button>
                    {ctx?.selectedUploadedImages && ctx?.selectedUploadedImages.length > 0 ? (
                        <button onClick={handelConfirmDelete} className="w-[44px] h-[44px] rounded-lg bg-redButton hover:bg-redButtonHover flex items-center justify-center transition-colors mobile:w-[32px] mobile:h-[32px]">
                            <img src={Trash} alt="" />
                        </button>
                    ) : (
                        <button onClick={handleAddImage} className="bg-text text-background h-[44px] rounded-lg px-4 flex items-center gap-2 font-[Inter] font-semibold text-[14px] leading-[17px] mobile:h-[32px] mobile:px-0 mobile:w-[32px] mobile:justify-center hover:bg-secondaryText transition-colors mobile:hover:bg-text mobile:active:bg-secondaryText">
                            <img className={`${theme === 'light' ? 'icon-to-white' : ''}`} src={Plus} alt="" />
                            {!isMobile && (
                                "Upload"
                            )}
                        </button>
                    )}
                </div>
            </div>
            {imagesWithError && imagesWithError > 0 && showErrorBlock ? (
                <div className="bg-[#FF525D1A] w-full p-4 gap-4 flex items-center justify-between rounded-xl">
                    <p className="text-text text-[14px] leading-[17px] font-semibold">
                        There was an issue uploading {imagesWithError} {imagesWithError > 1 ? 'images.' : 'image.'}
                    </p>
                    <div className="flex items-center justify-center gap-4">
                        {imagesWithError === 1 && (
                            <button onClick={() => singleInputRef.current?.click()} className="h-[32px] rounded-lg px-3 bg-redButton hover:bg-redButtonHover transition-colors text-white text-[14px] leading-[17px] font-semibold">
                                Change Image
                            </button>
                        )}
                        <img
                            className={`${theme === 'light' ? '' : 'icon-to-white'} w-[24px] cursor-pointer`}
                            onClick={() => setShowErrorBlock(false)}
                            src={Close}
                            alt=""
                        />
                    </div>
                </div>
            ) : null}
            {(ctx?.isCompressingImages || ctx?.isValidating || ctx?.isJustFinishedValidation) && (
                <div className={`${ctx?.isJustFinishedValidation ? 'bg-lightBlueBackground' : 'bg-inputSecondaryBackground'} transition-colors border-solid border-[1px] border-inputBackground rounded-xl p-4 w-full gap-3 flex items-center justify-between duration-500 mobile:flex-col mobile:gap-2`}>
                    {ctx?.isJustFinishedValidation ? (
                        <div className="flex items-center gap-3 select-none mobile:gap-2 w-full">
                            <img className="mobile:w-[20px]" src={CompleteValidation} alt="" />
                            <div className="flex flex-col mobile:items-start">
                                <p className="text-base font-semibold text-text mobile:text-[14px] leading-[17px]">
                                    Training Complete!
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex items-center gap-3 select-none mobile:gap-2 mobile:items-start w-full">
                            <img className="mobile:w-[20px]" src={Validating} alt="" />
                            <div className="flex flex-col gap-1">
                                <p className="text-base font-semibold text-text mobile:text-[14px] leading-[17px]">
                                    We’re touching up your images and training our AI model
                                </p>
                                <span className="text-[14px] leading-[17px] text-text mobile:text-[12px] mobile:leading-[18px]">
                                    Hang tight, this may take a few minutes.
                                </span>
                            </div>
                        </div>
                    )}
                    {ctx?.isJustFinishedValidation ? (
                        <div className="flex items-center gap-1 select-none mobile:ps-[27px] w-full justify-end max-w-[300px] mobile:max-w-full">
                            <div className="w-[240px] h-2 rounded-full overflow-hidden bg-inputBorder mobile:w-full">
                                <div style={{ width: '100%', minWidth: '8px' }} className="bg-button rounded-full h-full"></div>
                            </div>
                            <span className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] w-[50px] text-end">
                                100%
                            </span>
                        </div>
                    ) : (
                        <div className="flex items-center gap-1 select-none mobile:ps-[27px] w-full justify-end max-w-[300px] mobile:max-w-full">
                            <div className="w-[240px] h-2 rounded-full overflow-hidden bg-inputBorder mobile:w-full">
                                <div
                                    style={{ width: `${ctx?.trainingProgress}%`, minWidth: '8px' }}
                                    className="bg-button rounded-full h-full"
                                ></div>
                            </div>
                            <span className="text-base text-secondaryText mobile:text-[14px] mobile:leading-[17px] w-[50px] text-end">
                                {ctx?.trainingProgress}%
                            </span>
                        </div>
                    )}
                </div>
            )}
            <div className="grid grid-cols-[repeat(auto-fill,minmax(180px,1fr))] gap-[10px] mobile:grid-cols-[repeat(auto-fill,minmax(110px,1fr))]">
                {ctx?.uploadedImages.map((image, index) => (
                    <UploadedImageItem
                        image={image}
                        index={index}
                        key={index}
                    />
                ))}
            </div>
            {
                showDeleteModal && (
                    <RemoveUploadedImageModal onClose={() => setShowDeleteModal(false)} />
                )
            }
            <input
                type="file"
                accept="image/*"
                ref={singleInputRef}
                onChange={(e) => handleImageUploaded(e)}
                className="hidden"
            />
            <input
                multiple
                ref={inputRef}
                onChange={(e) => handleUploadFiles(e)}
                className="hidden"
                type="file"
                accept="image/*"
            />
        </div >
    )
}

export default UploadedImages